import { Report } from '@/models/Report';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { CancelReason } from '@/models/CancelReason';
import { Rpc } from '@/models/Rpc';
import { Event } from '@/models/Event';

@Component<CancelAppointmentDialog>({})
export default class CancelAppointmentDialog extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */

  @Prop()
  protected report!: Report;

  @Prop()
  protected event!: Event;
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  protected isLoading = false;

  protected cancelReasons: CancelReason[] = [];

  protected selectedCancelReason = '';

  protected cancelReasonComment = '';

  protected confirmEmailToApplicant = true;
  // #endregion

  // #region Lifecycle Hooks / Init

  // #endregion

  // #region Class methods

  protected mounted() {
    this.fetchCancelReasons();
  }

  protected close() {
    this.$emit('input', false);
    this.$emit('close');
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */

  protected async fetchCancelReasons(): Promise<void> {
    this.cancelReasons = await new CancelReason()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async cancelAppointment() {
    if (! this.event?.id) {
      return;
    }

    this.isLoading = true;

    const payload: cancelAppointmentPayload = {
      signature: 'report:appointment:cancel',
      body: {
        event: this.event.id,
        settings: {
          confirm_email_to_applicant: this.confirmEmailToApplicant,
        },
      },
    };

    if (this.report && ! this.report.isImmaterial) {
      payload.body.reason = this.selectedCancelReason;
      payload.body.reason_comment = this.cancelReasonComment;
    }

    await new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.$emit('appointmentCanceled');
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isLoading = false;
  }

  // #endregion

  // #region Getters & Setters

  protected get isCanceledReasonOther() {
    return this.selectedCancelReason === '4cf74681-deff-476c-9bb9-0f8736e0ec9f';
  }

  protected get isDisabledCancelAppointment() {
    if (this.report.isImmaterial) {
      return false;
    }

    if (this.isCanceledReasonOther) {
      return ! this.selectedCancelReason && ! this.cancelReasonComment;
    }

    return ! this.selectedCancelReason;
  }
  // #endregion

  // #region @Watchers

  // #endregion
}

interface cancelAppointmentPayload {
  signature: string;
  body: {
    event: string;
    reason?: string
    reason_comment?: string;
    settings: {
      confirm_email_to_applicant: boolean;
    },
  }
}
