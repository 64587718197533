import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { formatDate } from '@/support/String';
import { ReportLog } from '@/models/ReportLog';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';

@Component<ReportAppointmentCommunication>({})
export default class ReportAppointmentCommunication extends Vue {
  @Prop()
  protected report!: Report;

  protected isLoading = true;

  protected contactTypeMap: ContactTypeMap = {
    phone_incoming: {
      type: 'Telefoon',
      status: 'Inkomend',
    },
    phone_outgoing: {
      type: 'Telefoon',
      status: 'Uitgaand',
    },
    email_incoming: {
      type: 'Email',
      status: 'Inkomend',
    },
    email_outgoing: {
      type: 'Email',
      status: 'Uitgaand',
    },
    sms_incoming: {
      type: 'SMS',
      status: 'Inkomend',
    },
    sms_outgoing: {
      type: 'SMS',
      status: 'Uitgaand',
    },
  };

  // Planning contactmoments
  protected contactMoments: ContactMoment[] = [];

  protected mounted() {
    if (! this.report) {
      return;
    }

    this.fetchReportLogs();
  }

  protected fetchReportLogs() {
    this.isLoading = true;
    new ReportLog()
      .filter({
        report: this.report.uuid,
        types: ['phone_incoming', 'email_incoming', 'sms_incoming', 'phone_outgoing', 'email_outgoing', 'sms_outgoing'],
      })
      .all()
      .then((reportLogs: ReportLog[]) => {
        this.parseReportLogs(reportLogs);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected parseReportLogs(reportLogs: ReportLog[]) {
    if (! reportLogs) {
      return;
    }

    this.contactMoments = reportLogs.map((reportLog: ReportLog) => {
      const contactMoment: ContactMoment = {
        date: reportLog.created_at ? formatDate(reportLog.created_at) : '',
        time: reportLog.created_at ? formatDate(reportLog.created_at, 'HH:mm') : '',
        type: reportLog.type && this.contactTypeMap[reportLog.type] ? this.contactTypeMap[reportLog.type].type : '',
        contact_type: reportLog.type && this.contactTypeMap[reportLog.type] ? this.contactTypeMap[reportLog.type].status : '',
        delivery_status: reportLog.delivery_status ? reportLog.delivery_status : '',
        expert: reportLog.user ? reportLog.user : '',
        contact: reportLog.counterpart_name ? reportLog.counterpart_name : '',
        comment: reportLog.text ? reportLog.text : '',
      };

      return contactMoment;
    });
  }
}

interface ContactMoment {
  date: string;
  time: string;
  type: string;
  contact_type: string;
  delivery_status: string;
  expert: string;
  contact: string;
  comment: string;
}

interface ContactTypeMap {
  [key: string]: {
    type: string;
    status: string;
  }
}
