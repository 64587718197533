import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { formatDate } from '@/support/String';
import { Event, EventStatus, getAppointmentType } from '@/models/Event';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { getUserTypeName, UserRole, User, userRoles } from '@/models/User';
import { Options } from '@/components/mi-dialog/MiDialog';
import CancelAppointmentDialog from '@/components/dialog/cancel-appointment-dialog/CancelAppointmentDialog.vue';
import EditPlannedEventDialog from '@/components/dialog/edit-planned-event-dialog/EditPlannedEventDialog.vue';
import { organizationUuid } from '@/models/Organization';

@Component<ReportAppointment>({
  components: {
    CancelAppointmentDialog,
    EditPlannedEventDialog,
  },
})
export default class ReportAppointment extends Vue {
  @Prop()
  protected report!: Report;

  @Prop({ default: false })
  protected disableEditing!: boolean;

  @Prop({ default: false })
  protected dmzForExperts!: boolean;

  protected isEditingEvent = false;

  // mask
  protected mask: {[key: string]: string} = {
    time: '##:##',
  };

  protected confirmEmailToApplicant = true;

  protected isCancellingAppointment = false;

  protected isLoading = true;

  protected isLoadingEvent = true;

  protected userRoles = userRoles;

  protected appointmentStatusMap: AppointmentStatusMap = {
    canceled: {
      name: 'Geannuleerd',
      color: 'red',
    },
    completed: {
      name: 'Gelopen',
      color: 'success',
    },
    planned: {
      name: 'Gepland',
      color: 'orange',
    },
  };

  protected appointmentTypeMap: {[key: string]: string} = {
    Opname: 'Opname',
    Vervolgopname: 'Vervolgopname',
  };

  protected appointmentCancelReasons: {[key: string]: string}[] = [
    {
      label: 'Aanvrager is ziek',
      value: 'Aanvrager is ziek',
    },
    {
      label: 'Deskundige is ziek',
      value: 'Deskundige is ziek',
    },
    {
      label: 'Afgemeld door aanvrager',
      value: 'Afgemeld door aanvrager',
    },
  ];

  protected appointmentReasons: {[key: string]: string}[] = [
    {
      label: 'Meer tijd - nog op te nemen schades',
      value: 'Meer tijd - nog op te nemen schades',
    },
    {
      label: 'Nader advies nodig',
      value: 'Nader advies nodig',
    },
    {
      label: 'Nader onderzoek nodig (Hoogwerker)',
      value: 'Nader onderzoek nodig (Hoogwerker)',
    },
  ];

  protected events: Event[] = [];

  protected selectedEvent: Event | null = null;

  protected mounted() {
    if (! this.report) {
      return;
    }

    this.fetchEvents();
  }

  protected fetchEvents() {
    this.isLoading = true;
    new Event()
      .dmz(this.dmzForExperts && ! this.$store.state.isServiceOrganization ? organizationUuid.img : '')
      .filter({ report: this.report.uuid })
      .include(['user', 'organization', 'mediator', 'mediator_event', 'is_mediator_present', 'address'])
      .all()
      .then((events: Event[]) => {
        this.events = events;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected changeExpert(event: Event) {
    this.$emit('isEditingEventExpert', event);
  }

  protected getTimeOfEvent(event: Event) {
    return event.starts_at && event.ends_at ? `${formatDate(event.starts_at, 'HH:mm')} - ${formatDate(event.ends_at, 'HH:mm')}` : '';
  }

  protected getStatusColor(event: Event) {
    return event.status && this.appointmentStatusMap[event.status] ? this.appointmentStatusMap[event.status].color : this.appointmentStatusMap.planned.color;
  }

  protected getStatusName(event: Event) {
    return event.status && this.appointmentStatusMap[event.status] ? this.appointmentStatusMap[event.status].name : this.appointmentStatusMap.planned.name;
  }

  protected canChangeExpert(event: Event): boolean {
    const user = new User(event.user);
    return ! this.$store.state.isServiceOrganization && this.$store.state.Auth.hasRole(this.userRoles.PlanningRoles) && (event.status === EventStatus.PLANNED) && ! user.hasRole(UserRole.CASE_MEDIATOR);
  }

  protected getUserTypeName(event: Event) {
    if (! event || ! event.user || ! event.user.type) {
      return '';
    }

    return getUserTypeName(event.user.type);
  }

  protected editEvent(event: Event) {
    this.selectedEvent = event;
    this.isEditingEvent = true;
  }

  protected eventChanged() {
    this.$store.dispatch('openDialog', this.updateEventDialogOptions);
    this.fetchEvents();
  }

  protected getAppointmentTypeLabel(key: string) {
    const type = getAppointmentType(key);
    return type ? type.label : '';
  }

  protected canEditAppointment(event: Event) {
    return this.$store.state.isServiceOrganization
    && event.status === 'planned';
  }

  protected canCancelAppointment(event: Event) {
    return this.$store.state.isServiceOrganization && event.status === 'planned';
  }

  protected appointmentCanceled() {
    this.$store.dispatch('openDialog', this.cancelSuccessDialogOptions);
    this.fetchEvents();
  }

  protected get updateEventDialogOptions(): Options {
    return {
      title: 'Afspraak succesvol aangepast',
      text: '',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected get cancelSuccessDialogOptions(): Options {
    return {
      title: 'Afspraak succesvol geannuleerd',
      text: '',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }
}

interface AppointmentStatusMap {
  [key: string]: {
    name: string;
    color: string;
  }
}
