var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "v-layout",
            [
              _vm.report
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("h3", [_vm._v("Afspraken")]),
                      !_vm.events.length
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "item__list",
                              attrs: { wrap: "", "mb-0": "" }
                            },
                            [
                              _c("v-flex", { attrs: { "xs-12": "" } }, [
                                _c("p", [
                                  _vm._v(
                                    "Er zijn geen afspraken voor dit dossier"
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.events.length
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "item__list",
                              attrs: { wrap: "", "mb-4": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { staticClass: "label", attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c("v-flex", { attrs: { xs2: "" } }, [
                                        _c("span", [_vm._v("Bezoekdatum")])
                                      ]),
                                      _c("v-flex", { attrs: { xs1: "" } }, [
                                        _c("span", [_vm._v("Type")])
                                      ]),
                                      !_vm.$store.state.isServiceOrganization
                                        ? [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs2: "" } },
                                              [
                                                _c("span", [
                                                  _vm._v("Aanwezigen")
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs2: "" } },
                                              [
                                                _c("span", [
                                                  _vm._v("Zaakbegeleider")
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs2: "" } },
                                              [_c("span", [_vm._v("Contact")])]
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.$store.state.isServiceOrganization
                                        ? [
                                            _c(
                                              "v-flex",
                                              {
                                                class: _vm.report.isImmaterial
                                                  ? "xs2"
                                                  : "xs3"
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Aanwezigen")
                                                ])
                                              ]
                                            ),
                                            _vm.report.isImmaterial
                                              ? _c(
                                                  "v-flex",
                                                  { attrs: { xs2: "" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Adres")
                                                    ])
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-flex",
                                              {
                                                class: _vm.report.isImmaterial
                                                  ? "xs2"
                                                  : "xs2"
                                              },
                                              [_c("span", [_vm._v("Contact")])]
                                            )
                                          ]
                                        : _vm._e(),
                                      _c("v-flex", { attrs: { xs1: "" } }, [
                                        _c("span", [_vm._v("Status")])
                                      ]),
                                      _c("v-flex", { attrs: { xs2: "" } })
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _vm._l(_vm.events, function(event, index) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: "event-" + index,
                                    staticClass: "list__item",
                                    attrs: { xs12: "" }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: { wrap: "", "align-center": "" }
                                      },
                                      [
                                        _c("v-flex", { attrs: { xs2: "" } }, [
                                          _c("b", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    event.date
                                                  )
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.getTimeOfEvent(event))
                                            )
                                          ])
                                        ]),
                                        _c("v-flex", { attrs: { xs1: "" } }, [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getAppointmentTypeLabel(
                                                  event.appointment_type
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        !_vm.$store.state.isServiceOrganization
                                          ? [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs2: "" } },
                                                [
                                                  event.user
                                                    ? _c("span", [
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              event.user.name
                                                            )
                                                          )
                                                        ])
                                                      ])
                                                    : _vm._e(),
                                                  _c("br"),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        event.organization.name
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.getUserTypeName(
                                                            event
                                                          )
                                                        )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs2: "" } },
                                                [
                                                  event.is_mediator_present
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "success"
                                                              }
                                                            },
                                                            [_vm._v("check")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs2: "" } },
                                                [
                                                  event.user
                                                    ? _c("span", [
                                                        _c("b", [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  "tel:" +
                                                                  event.user
                                                                    .phone
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  event.user
                                                                    .phone
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      ])
                                                    : _vm._e(),
                                                  _c("br"),
                                                  event.user
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            event.user
                                                              .business_email
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          : _vm._e(),
                                        _vm.$store.state.isServiceOrganization
                                          ? [
                                              _c(
                                                "v-flex",
                                                {
                                                  class: _vm.report.isImmaterial
                                                    ? "xs2"
                                                    : "xs3"
                                                },
                                                [
                                                  event.user
                                                    ? _c("span", [
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              event.user.name
                                                            )
                                                          )
                                                        ])
                                                      ])
                                                    : _vm._e(),
                                                  _c("br"),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        event.organization.name
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.getUserTypeName(
                                                            event
                                                          )
                                                        )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm.report.isImmaterial
                                                ? _c(
                                                    "v-flex",
                                                    { attrs: { xs2: "" } },
                                                    [
                                                      event.address
                                                        ? [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "addressLine"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    event
                                                                      .address
                                                                      .street
                                                                  ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      event
                                                                        .address
                                                                        .number
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "addressLine addressLine--small"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      event
                                                                        .address
                                                                        .postcode
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      event
                                                                        .address
                                                                        .city
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-flex",
                                                {
                                                  class: _vm.report.isImmaterial
                                                    ? "xs2"
                                                    : "xs2"
                                                },
                                                [
                                                  event.user
                                                    ? _c("span", [
                                                        _c("b", [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  "tel:" +
                                                                  event.user
                                                                    .phone
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  event.user
                                                                    .phone
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      ])
                                                    : _vm._e(),
                                                  _c("br"),
                                                  event.user
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            event.user
                                                              .business_email
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          : _vm._e(),
                                        _c("v-flex", { attrs: { xs1: "" } }, [
                                          _c(
                                            "span",
                                            {
                                              class:
                                                "statusPill statusBackgroundColor--" +
                                                _vm.getStatusColor(event)
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getStatusName(event)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: { xs2: "", "text-right": "" }
                                          },
                                          [
                                            _vm.canChangeExpert(event)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "inline-block item__icon item__icon--action"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      {},
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeExpert(
                                                                                event
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "perm_contact_calendar"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Deskundige wijzigen"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.canEditAppointment(event)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "inline-block item__icon item__icon--action"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      {},
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.editEvent(
                                                                                event
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "edit"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Afspraak bewerken"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.canCancelAppointment(event)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "inline-block item__icon item__icon--action remove",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.isCancellingAppointment = true
                                                        _vm.selectedEvent = event
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      {},
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "event_busy"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Annuleer afspraak"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.isEditingEvent
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500", persistent: true },
              model: {
                value: _vm.isEditingEvent,
                callback: function($$v) {
                  _vm.isEditingEvent = $$v
                },
                expression: "isEditingEvent"
              }
            },
            [
              _c("EditPlannedEventDialog", {
                attrs: { event: _vm.selectedEvent },
                on: { eventChanged: _vm.eventChanged },
                model: {
                  value: _vm.isEditingEvent,
                  callback: function($$v) {
                    _vm.isEditingEvent = $$v
                  },
                  expression: "isEditingEvent"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isCancellingAppointment
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500", persistent: true },
              model: {
                value: _vm.isCancellingAppointment,
                callback: function($$v) {
                  _vm.isCancellingAppointment = $$v
                },
                expression: "isCancellingAppointment"
              }
            },
            [
              _c("CancelAppointmentDialog", {
                attrs: { event: _vm.selectedEvent, report: _vm.report },
                on: { appointmentCanceled: _vm.appointmentCanceled },
                model: {
                  value: _vm.isCancellingAppointment,
                  callback: function($$v) {
                    _vm.isCancellingAppointment = $$v
                  },
                  expression: "isCancellingAppointment"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }