var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    {
      on: {
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("template", { slot: "header" }, [_vm._v("Afspraak annuleren")]),
      _vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [
                _c("LoaderCard", {
                  attrs: { flat: "", type: "spinner--center" }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _c("p", [
                        _vm._v(
                          "Weet je zeker dat je deze afspraak wil annuleren?"
                        )
                      ])
                    ]),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            label:
                              "Afspraakbevestiging versturen per e-mail aan de aanvrager",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.confirmEmailToApplicant,
                            callback: function($$v) {
                              _vm.confirmEmailToApplicant = $$v
                            },
                            expression: "confirmEmailToApplicant"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.report.isImmaterial
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                placeholder: "Reden van annulering",
                                items: _vm.cancelReasons,
                                "item-text": "name",
                                "item-value": "id"
                              },
                              model: {
                                value: _vm.selectedCancelReason,
                                callback: function($$v) {
                                  _vm.selectedCancelReason = $$v
                                },
                                expression: "selectedCancelReason"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isCanceledReasonOther
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-textarea", {
                              attrs: { label: "Toelichting" },
                              model: {
                                value: _vm.cancelReasonComment,
                                callback: function($$v) {
                                  _vm.cancelReasonComment = $$v
                                },
                                expression: "cancelReasonComment"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "v-layout",
                  { attrs: { "justify-end": "", "align-center": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        [
                          _c(
                            "span",
                            {
                              staticClass: "fadedText link mr-3",
                              on: {
                                click: function($event) {
                                  _vm.isCancellingAppointment = false
                                }
                              }
                            },
                            [_vm._v("Nee, dat wil ik niet")]
                          ),
                          _c(
                            "MiButton",
                            {
                              attrs: {
                                color: "warning",
                                small: "true",
                                disabled: _vm.isDisabledCancelAppointment
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.cancelAppointment($event)
                                }
                              }
                            },
                            [_vm._v(" Ja, afspraak annuleren. ")]
                          )
                        ]
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }