import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Rpc } from '@/models/Rpc';
import { Event } from '@/models/Event';
import { UserRole, User } from '@/models/User';
import { debounce } from 'lodash';
import { formatDate } from '@/support/String';
import { DateTime } from 'luxon';

@Component<EditPlannedEventDialog>({})
export default class EditPlannedEventDialog extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */

  @Prop()
  protected event!: Event;
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  protected isLoading = false;

  protected users: User[] = [];

  protected userSearch = '';

  protected withoutMediator = false;

  protected debouncedSearchUsers: Function = debounce(this.fetchUsers, 300)

  protected date = '';

  protected organization = '';

  protected payload = {
    user: '',
    date: '',
    starts_at: '',
    ends_at: '',
    settings: {
      confirm_email_to_applicant: true,
    },
  };
  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted() {
    this.parseEvent();
    this.fetchUsers();
  }
  // #endregion

  // #region Class methods

  protected parseEvent() {
    this.payload.starts_at = this.formatTime(this.event?.starts_at || '');
    this.payload.ends_at = this.formatTime(this.event?.ends_at || '');
    this.payload.date = this.formatDate(this.event?.starts_at || '');
    this.payload.user = this.event.user?.uuid || '';

    if (this.event.isExpert) {
      this.organization = this.event.organization?.id || '';
    }
  }

  protected formatTime(date: string) {
    return date ? formatDate(date, 'HH:mm') : '';
  }

  protected formatDate(date: string) {
    return date ? formatDate(date, 'yyyy-LL-dd') : DateTime.local().toFormat('yyyy-LL-dd');
  }

  protected close() {
    this.$emit('input', false);
    this.$emit('close');
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */

  protected async fetchUsers() {
    if (! this.event) {
      return;
    }

    const filters: {[key: string]: string} = {
      search: this.userSearch,
    };

    let model = new User();

    if (this.event.isZb) {
      filters.type = UserRole.CASE_MEDIATOR;
    }

    if (this.event.isExpert && this.event.organization?.id) {
      model = model.dmz(this.event.organization.id);
    }

    const users: User[] = await model
      .filter(filters)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (users) {
      this.users = users;
    }

    if (this.event?.user?.uuid) {
      this.users.push(this.event.user);
    }
  }

  protected async updateEvent() {
    if (! this.event) {
      return;
    }

    const payload = {
      signature: 'report:appointment:edit',
      body: {
        event: this.event.id,
        starts_at: `${this.payload.date} ${this.payload.starts_at}:00`,
        ends_at: `${this.payload.date} ${this.payload.ends_at}:00`,
        user: this.payload.user,
        settings: {
          confirm_email_to_applicant: this.payload.settings.confirm_email_to_applicant,
        },
      },
    };

    this.isLoading = true;

    await new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.$emit('eventChanged');
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  // #endregion

  // #region Getters & Setters

  // #endregion

  // #region @Watchers

  @Watch('userSearch')
  protected userSearchChanged(query: string) {
    if (query && query.length) {
      this.debouncedSearchUsers();
    }
  }
  // #endregion
}
