var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    {
      on: {
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("template", { slot: "header" }, [_vm._v("Afspraak aanpassen")]),
      _vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [
                _c("LoaderCard", {
                  attrs: { flat: "", type: "spinner--center" }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs6: "" } },
                      [
                        _c("DateFilter", {
                          attrs: { label: "Datum" },
                          model: {
                            value: _vm.payload.date,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "date", $$v)
                            },
                            expression: "payload.date"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs3: "" } },
                      [
                        _c("v-text-field", {
                          staticClass: "input input-time",
                          attrs: {
                            type: "time",
                            placeholder: "HH:MM",
                            label: "Start tijd"
                          },
                          model: {
                            value: _vm.payload.starts_at,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "starts_at", $$v)
                            },
                            expression: "payload.starts_at"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs3: "" } },
                      [
                        _c("v-text-field", {
                          staticClass: "input input-time",
                          attrs: {
                            type: "time",
                            placeholder: "HH:MM",
                            label: "Eind tijd"
                          },
                          model: {
                            value: _vm.payload.ends_at,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "ends_at", $$v)
                            },
                            expression: "payload.ends_at"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "py-3": "" } },
                      [
                        _c("v-autocomplete", {
                          staticClass: "with-border",
                          attrs: {
                            "prepend-icon": "event",
                            items: _vm.users,
                            label: "Gebruikers",
                            "item-text": "name",
                            "item-value": "uuid",
                            "hide-details": "",
                            "search-input": _vm.userSearch
                          },
                          on: {
                            "update:searchInput": function($event) {
                              _vm.userSearch = $event
                            },
                            "update:search-input": function($event) {
                              _vm.userSearch = $event
                            },
                            keyup: _vm.debouncedSearchUsers
                          },
                          model: {
                            value: _vm.payload.user,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "user", $$v)
                            },
                            expression: "payload.user"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            label:
                              "Wijzigingen versturen per e-mail aan de aanvrager",
                            "hide-details": ""
                          },
                          model: {
                            value:
                              _vm.payload.settings.confirm_email_to_applicant,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.payload.settings,
                                "confirm_email_to_applicant",
                                $$v
                              )
                            },
                            expression:
                              "payload.settings.confirm_email_to_applicant"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "template",
              { slot: "actions" },
              [
                !_vm.isLoading
                  ? _c(
                      "v-layout",
                      { attrs: { "justify-end": "", "align-center": "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { shrink: "" } },
                          [
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "fadedText link mr-3",
                                  on: {
                                    click: function($event) {
                                      return _vm.close()
                                    }
                                  }
                                },
                                [_vm._v("Nee, dat wil ik niet")]
                              ),
                              _c(
                                "MiButton",
                                {
                                  attrs: { color: "success", small: "true" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.updateEvent($event)
                                    }
                                  }
                                },
                                [_vm._v("Ja, afspraak aanpassen.")]
                              )
                            ]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }