var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "v-layout",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("h3", [_vm._v("Contactmomenten")]),
                  !_vm.contactMoments.length
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "item__list",
                          attrs: { wrap: "", "mb-0": "" }
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("p", [
                              _vm._v(
                                "Er zijn geen contactmomenten bekend voor dit dossier"
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.contactMoments.length
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "item__list",
                          attrs: { wrap: "", "mb-4": "" }
                        },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "label", attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { xs2: "" } }, [
                                    _c("span", [_vm._v("datum")])
                                  ]),
                                  _c("v-flex", { attrs: { xs2: "" } }, [
                                    _c("span", { staticClass: "pl-3" }, [
                                      _vm._v("Type")
                                    ])
                                  ]),
                                  _c("v-flex", { attrs: { xs2: "" } }, [
                                    _c("span", [_vm._v("Medewerker")])
                                  ]),
                                  _c("v-flex", { attrs: { xs2: "" } }, [
                                    _c("span", [_vm._v("Contactpersoon")])
                                  ]),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _vm._v("Notitie")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.contactMoments, function(
                            contactMoment,
                            index
                          ) {
                            return _c(
                              "v-flex",
                              {
                                key: "contactMoment-" + index,
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs2: "" } }, [
                                      _c("b", [
                                        _c("span", [
                                          _vm._v(_vm._s(contactMoment.date))
                                        ])
                                      ]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(_vm._s(contactMoment.time))
                                      ])
                                    ]),
                                    _c("v-flex", { attrs: { xs2: "" } }, [
                                      contactMoment.delivery_status
                                        ? _c("span", {
                                            class:
                                              "status-contact-dot status-contact-dot--" +
                                              contactMoment.delivery_status
                                          })
                                        : _vm._e(),
                                      _c("div", { staticClass: "pl-3" }, [
                                        _c("b", [
                                          _c("span", [
                                            _vm._v(_vm._s(contactMoment.type))
                                          ])
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(contactMoment.contact_type)
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c("v-flex", { attrs: { xs2: "" } }, [
                                      _c("span", [
                                        _vm._v(_vm._s(contactMoment.expert))
                                      ])
                                    ]),
                                    _c("v-flex", { attrs: { xs2: "" } }, [
                                      _c("span", [
                                        _vm._v(_vm._s(contactMoment.contact))
                                      ])
                                    ]),
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _vm._v(_vm._s(contactMoment.comment))
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }